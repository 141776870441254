<template>
  <div class="ccc">
    <TopHead :isMessage="true"></TopHead>
    <logoNav></logoNav>
    <!--页面主体-->
    <section class="main">
      <div class="container">
        <div class="left">
          <div class="boxname">
            <h1 v-if="$route.query.type == 'message'"><span></span>新闻公告</h1>
            <h1 v-else><span></span>知识库</h1>
          </div>
          <div class="content">
            <div class="titleList">{{info.title}}</div>
            <div class="origino" v-if="$route.query.type == 'message'">来源：<span>{{info.source || '--'}}</span></div>
            <div class="time" v-if="$route.query.type == 'message'">时间：<span>{{info.writtenTime}}</span></div>
            <div class="newscontent">
              <div v-if="info.content" v-html="info.content"></div>
              <div class="loading" v-if="isLoading">
                <div class="loading-animate">
                  <span></span><span></span><span></span><span></span><span></span>
                </div>
                <div class="loading-text">数据加载中</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <a href="javascript:;" title="返回" class="goback" @click="$router.push('/message')">返回上一级</a>
          <div class="list">
            <p class="fs_15 mb_20" v-if="$route.query.type == 'message'">新闻列表</p>
            <p class="fs_15 mb_20" v-else>知识库列表</p>
            <ul>
              <li v-for="(item,index) in noticeArr" :key="index" @click="goMessage(item)" class="cur_p" style="line-height: 25px;">{{item.title}}</li>
              <!--<p class="nodata"><img src="../assets/images/loading.gif" alt="数据加载中" title="数据加载中" /></p>-->
              <!--<p class="nodata"><img src="" alt="数据加载中" title="数据加载中" /></p>-->
            </ul>
          </div>
        </div>
      </div>
    </section>>
  </div>
</template>

<script>
  export default {
    name: "messageDetails",
    data() {
      return {
        info:{},
        pageInfo:{
          "pageIndex":1,
          "pageSize":11,
          "data":{'title':'','newsClassificationId':'3798776'}
        },
        noticeArr:[],
        isLoading:true,
      }
    },
    created() {
      if(!this.$route.query.type){
        this.pageInfo.data.newsClassificationId = '3798782'
      }else {
        this.pageInfo.data.newsClassificationId = '3798776'
      }
      this.getByIdNotice();
      this.getNotice();
    },
    methods: {
      goMessage(row){
        let routeUrl = this.$router.resolve({
          path:'/messageDetails',
          query:{
            newsId:row.newsId,
            type:'message',
          }
        });
        window.open(routeUrl.href, '_blank');
      },
      //获取通知公告
      getNotice(){
        this.$api.queryPage(this.pageInfo).then(res => {
          this.noticeArr = res.data.rows;
        })
      },
      getByIdNotice(){
        this.$api.getByIdNotice(this.$route.query.newsId).then(res => {
          this.info = res.data;
          this.isLoading = false;
        })
      }
    }
  }
</script>

<style scoped>
  .main .left {
    width: calc(100% - 290px);
    display: inline-block;
    vertical-align: top;
    min-height: 618px;
    background: #fff;
    border-radius: 10px;
    margin: 20px 0 20px 0;
  }

  .main .right {
    width: 280px;
    display: inline-block;
    min-height: 618px;
    background: #fff;
    border-radius: 10px;
    margin: 20px 0 10px 10px;
    padding: 20px;
    box-sizing: border-box;
  }

  .main .left .boxname{
    height: 56px;
    padding: 0 20px;
  }

  .main .left .boxname h1{
    font-size: 20px;
    text-align: left;
    line-height: 56px;
  }

  .main .left .boxname h1 span{
    display: inline-block;
    width: 4px;
    vertical-align: middle;
    background: #1cd0bd;
    height: 20px;
    margin: -3px 10px 0 0;
  }

  .main .left .content{
    margin: 0 20px;
    border-top: 2px solid #f0f1f3;
  }

  .main .left .content .titleList{
    font-size: 20px;
    margin: 20px 0;
    font-weight: bold;
  }
  .main .left .content .titleList h1{
    font-size: 20px;
    line-height: 20px;
    margin-top: 10px;
    font-weight: bold;
  }
  .main .left .content .origino,.main .left .content .time{
    font-size: 14px;
    color: #999;
    display: inline-block;
    line-height: 30px;
  }

  .main .left .content .time{
    margin-left: 80px;
  }

  .main .left .content .newscontent{
    padding: 30px 0;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }

  .main .right a.goback{
    border-radius: 5px;
    background: #1cd0bd;
    font-size: 20px;
    line-height: 65px;
    display: block;
    color: #fff;
  }

  .main .right a.goback:hover{
    color: #fff;
    font-size: 23px;
  }

  .main .right .list{
    margin-top: 20px;
    text-align: left
  }

  .main .right .list h1{
    font-size: 20px;
    line-height: 56px;
  }

  .main .right .list li{
    font-size: 14px;
    line-height: 33px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    list-style: inside;
    transition: all 0.25s ease 0.1s;
  }
  .main .right .list li:hover,.main .right .list li a:hover{
    color: #fec714;
  }
  .main .right .list li span{
    display: inline-block;
  }
  .main .right .list li span:first-of-type{
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  .main .right .list li span:last-of-type{
    width: 80px;
    text-align: right;
  }
</style>
